import styles from './TestimonialsBlock.module.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextWithIconBlock from '@/components/models/cms/blocks/TextWithIconBlock'
import travelTestimonialObject from '@/entities/travelTestimonial/interface'

interface Props {
  testimonials: travelTestimonialObject[]
}

const TestimonialsBlock = ({ testimonials }: Props) => {
  if (!testimonials.length) {
    return <></>
  }

  return (
    <Container className={styles.root}>
      <Row>
        {testimonials.map((testimonial: travelTestimonialObject, index: number) => (
          <Col md={6} key={index}>
            <TextWithIconBlock {...testimonial.contentBlock} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default TestimonialsBlock
