import itineraryItemObject from '@/entities/itineraryItem/interface'
import itineraryObject from '@/entities/itinerary/interface'
import { ExtendedItineraryItem } from '@/interfaces'

// add dayString & dateString to itinerary items
export const buildExtendedItineraryItems = (itinerary: itineraryObject, startDate: Date, locale: string | undefined): ExtendedItineraryItem[] => {
  let prevDay = 0
  let currentDay = 0
  return itinerary.items.map((item: itineraryItemObject) => {
    prevDay = currentDay
    currentDay += item.duration
    return {
      ...item,
      dayString: getDayString(prevDay, currentDay),
      dateString: getDateString(startDate, item.duration, prevDay, currentDay, locale),
    } as ExtendedItineraryItem
  })
}

function getDayString(prevDay: number, currentDay: number) {
  return prevDay === currentDay - 1 ? currentDay : `${prevDay + 1}-${currentDay}`
}

function getDateString(startDate: Date, duration: number, prevDay: number, currentDay: number, locale: string | undefined) {
  if (locale === undefined) {
    locale = 'de'
  }

  const formatDate = (dateObject: Date) => {
    const year = dateObject.getUTCFullYear().toString().substr(-2)
    const month = ('0' + (dateObject.getUTCMonth() + 1)).slice(-2)
    const date = ('0' + dateObject.getUTCDate()).slice(-2)
    const day = dateObject.toLocaleDateString(locale, { weekday: 'short', timeZone: 'UTC' })
    return `${day}, ${date}.${month}.${year}`
  }

  const formatDateRange = (startDate: Date, endDate: Date) => {
    const start = startDate.toLocaleDateString(locale, { weekday: 'short', timeZone: 'UTC' })
    const end = endDate.toLocaleDateString(locale, { weekday: 'short', timeZone: 'UTC' })
    return `${start}-${end}`
  }

  const addDaysToDate = (date: Date, days: number) => {
    const result = new Date(date)
    result.setUTCDate(result.getUTCDate() + days)
    return result
  }

  const addDaysToDateValue = (prevDay === currentDay - 1 ? currentDay : prevDay + 1) - 1
  const isRange = prevDay !== currentDay - 1

  if (isRange) {
    const itemStartDate = addDaysToDate(startDate, addDaysToDateValue)
    const itemEndDate = addDaysToDate(startDate, addDaysToDateValue + (duration - 1))
    return formatDateRange(itemStartDate, itemEndDate)
  } else {
    const itemDate = addDaysToDate(startDate, addDaysToDateValue)
    return formatDate(itemDate)
  }
}
