import styles from './IntroBlockHighlights.module.scss'
import { IntroBlockHighlightsProps } from 'components/models/travel/IntroBlock/IntroBlockHighlights/IntroBlockHighlights'
import { useTranslation } from 'next-i18next'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

const IntroBlockHighlights = ({ highlights }: IntroBlockHighlightsProps) => {
  const { t } = useTranslation('common')

  return (
    <Row className={styles.root}>
      <Col xs={{ offset: 2 }} className={styles.inner}>
        <div className={styles.iconWrap}>
          <ThemeImage src={'/images/icons/highlights-icon.png'} width={120} height={120} alt="highlights icon" />
        </div>
        <div className={styles.title}>{t('travel.highlights')}</div>
        {highlights.map((highlight, index: number) => {
          return (
            <ul className={styles.list} key={index}>
              <li>{highlight.text}</li>
            </ul>
          )
        })}
      </Col>
    </Row>
  )
}

export default IntroBlockHighlights
