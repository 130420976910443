import styles from '@/components/models/travel/PricingBlock/PricingBlockInfo/PricingBlockInfo.module.scss'
import BrandedBox from '@/components/elements/BrandedBox'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

interface Props {
  pricingTable: string
  priceCalculationInfo: string
}
const PricingBlockInfo = ({ pricingTable, priceCalculationInfo }: Props) => {
  return (
    <Col xl={5} className={styles.root}>
      <BrandedBox className={styles.prices}>
        <Row className={'m-0'}>
          <Col xs={{ offset: 2 }} className={styles.inner}>
            <div className={styles.pricingTableWrap} dangerouslySetInnerHTML={{ __html: pricingTable }} />
            <div className={styles.pricingInfo}>{priceCalculationInfo}</div>
          </Col>
        </Row>
      </BrandedBox>
    </Col>
  )
}

export default PricingBlockInfo
