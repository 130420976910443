import travelObject from '@/entities/travel/interface'
import { TFunction } from 'next-i18next'
import travelGuideObject from '@/entities/travelGuide/interface'

export const buildSecondaryLabel = (travel: travelObject, t: TFunction) => {
  if (!travel.travelGuides.length) {
    return ''
  }
  let label = `${t('with')} `
  travel.travelGuides.forEach((guide: travelGuideObject, index: number) => {
    if (index > 0) {
      label += ` ${t('and')} `
    }
    label += `${guide.firstName} ${guide.lastName}`
  })
  return label
}
