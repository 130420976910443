import styles from './HeaderBlockInfoLabel.module.scss'
import CustomButton from '@/components/elements/buttons/CustomButton'

interface Props {
  label: string
}

const HeaderBlockInfoLabel = ({ label }: Props) => {
  return <CustomButton label={label} className={styles.infoLabelCustomButton} />
}

export default HeaderBlockInfoLabel
