import React from 'react'
import styles from './StarRatingDisplay.module.scss'
import { isFloat, isInt } from '@/lib/helpers/helpers'

interface Props {
  starsValue: string
}

const StarRatingDisplay = ({ starsValue }: Props) => {
  const fullStar = <span className={`${styles.star}`}>★</span>
  const halfStar = (
    <span className={`${styles.star} ${styles.half}`} data-forhalf={'★'}>
      ★
    </span>
  )

  // integer
  if (isInt(Number(starsValue))) {
    const starsInt = Number(starsValue)
    return (
      <div className={styles.root}>
        {[...Array(starsInt)].map((item, index) => (
          <React.Fragment key={index}>{fullStar}</React.Fragment>
        ))}
      </div>
    )
  }

  // float
  if (isFloat(Number(starsValue))) {
    const fullStarsCount = Math.floor(Number(starsValue))
    return (
      <div className={styles.root}>
        {[...Array(fullStarsCount)].map((item, index) => (
          <React.Fragment key={index}>{fullStar}</React.Fragment>
        ))}
        {halfStar}
      </div>
    )
  }

  // 5+
  if (starsValue === '5+') {
    return (
      <div className={styles.root}>
        {[...Array(5)].map((item, index) => (
          <React.Fragment key={index}>{fullStar}</React.Fragment>
        ))}
        +
      </div>
    )
  }

  return <></>
}

export default StarRatingDisplay
