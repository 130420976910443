import styles from '@/components/models/travelGuide/TeamsBlock/TeamsBlock.module.scss'
import { TitleH2 } from '@/components/cms/Title'
import { Col, Container, Row } from 'react-bootstrap'
import TravelGuideTeaser from '@/components/models/travelGuide/TravelGuideTeaser'
import travelTeamObject from '@/entities/travelTeam/interface'
import travelGuideObject from '@/entities/travelGuide/interface'

const buildContent = (travelGuides: Array<travelGuideObject>) => {
  let colSize: number = 4
  switch (travelGuides.length) {
    case 2:
    case 4:
      colSize = 6
      break
  }

  return (
    <Row>
      {travelGuides.map((tourGuide: travelGuideObject, index: number) => {
        return (
          <Col md={6} xl={colSize} className={'mb-3'} key={index}>
            <TravelGuideTeaser travelGuide={tourGuide} />
          </Col>
        )
      })}
    </Row>
  )
}

interface Props {
  teams: Array<travelTeamObject>
}

const TeamsBlock = ({ teams }: Props) => {
  if (teams.length) {
    return (
      <>
        <span id="teams" className="anchor" />
        {teams.map((team: travelTeamObject, index: number) => {
          return (
            <Container className={styles.root} key={index}>
              <Row>
                <Col>
                  <TitleH2 title={team.name} overlap={true} className={styles.title} />
                </Col>
              </Row>
              {buildContent(team.members)}
            </Container>
          )
        })}
      </>
    )
  }

  return <></>
}

export default TeamsBlock
