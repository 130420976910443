import styles from '@/components/models/accommodation/AccommodationDetailed/AccommodationDetailed.module.scss'
import accommodationObject from '@/entities/accommodation/interface'
import accommodationRoomTypeObject from '@/entities/accommodationRoomType/interface'
import AccommodationRoomType from '@/components/models/accommodation/AccommodationDetailed/AccommodationRoomType'
import AccommodationAvailableAddons from '@/components/models/accommodation/AccommodationDetailed/AccommodationAvailableAddons'
import GallerySlider from '@/components/models/media/GallerySlider'
import { Col, Container, Row } from 'react-bootstrap'
import StarRatingDisplay from '@/components/elements/starRating/StarRatingDisplay'

interface Props {
  accommodation: accommodationObject
  roomTypes: accommodationRoomTypeObject[]
}
const AccommodationDetailed = ({ accommodation, roomTypes }: Props) => {
  return (
    <>
      <Container>
        <Row>
          <Col xs={12}>
            <div className={styles.title}>
              <div className={`${styles.stars} clearfix`}>
                <StarRatingDisplay starsValue={accommodation.stars as unknown as string} />
              </div>
              <div>{accommodation.name}</div>
            </div>
          </Col>
          <Col xs={12}>
            <Row>
              <Col md={7} className={'mb-4'} dangerouslySetInnerHTML={{ __html: accommodation.description }} />
            </Row>
            <Row className={'mb-2'}>
              {roomTypes.map((roomType: accommodationRoomTypeObject, index: number) => {
                return (
                  <Col xxl={6} className={styles.roomTypeWrap} key={index}>
                    <AccommodationRoomType roomType={roomType} index={index} />
                  </Col>
                )
              })}
            </Row>
            <AccommodationAvailableAddons addons={accommodation.availableAddons} />
          </Col>
        </Row>
      </Container>
      <Container fluid className={'pl-0'}>
        <Row>
          <Col className={'p-0'}>
            <GallerySlider gallery={accommodation.gallery} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AccommodationDetailed
