import styles from './ItineraryAccordion.module.scss'
import Accordion from '@/components/elements/Accordion'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'
import { ExtendedItineraryItem } from '@/interfaces'

interface Props {
  extendedItineraryItems: ExtendedItineraryItem[]
  collapsed?: boolean
}

const ItineraryAccordion = ({ extendedItineraryItems, collapsed }: Props) => {
  const { t } = useTranslation('common')
  const colSizes = [1, 3]

  let items = extendedItineraryItems.map((item) => {
    // date
    return {
      title: (
        <Row>
          <Col md={colSizes[0]} className="d-none d-md-block">
            {item.dayString}
          </Col>
          <Col md={colSizes[1]} className={styles.dateWrap}>
            {item.dateString}
          </Col>
          <Col className="me-4">{item.title}</Col>
        </Row>
      ),
      content: (
        <Row>
          <Col md={{ offset: colSizes[0] + colSizes[1] }} className={styles.contentCol}>
            {item.text}
          </Col>
        </Row>
      ),
    }
  })

  return (
    <div className={styles.root}>
      <div className="d-none d-md-block">
        <Row className={styles.header}>
          <Col md={colSizes[0]}>{t('travel.itinerary.day')}</Col>
          <Col md={colSizes[1]} className={styles.dateHeading}>
            {t('travel.itinerary.date')}
          </Col>
          <Col>{t('travel.itinerary.program')}</Col>
        </Row>
      </div>
      <Accordion items={items} className={`mb-3 ${styles.accordion}`} collapsed={collapsed} />
    </div>
  )
}

export default ItineraryAccordion
