import styles from '@/components/models/accommodation/AccommodationDetailed/AccommodationRoomType/AccommodationRoomType.module.scss'
import accommodationRoomTypeObject from '@/entities/accommodationRoomType/interface'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import TextWithImageSlider from '@/components/models/cms/blocks/TextWithImageBlock/TextWithImageSlider'
import { PrintConfigContext } from '@/app/providers/PrintConfigProvider'
import { useContext } from 'react'

interface Props {
  roomType: accommodationRoomTypeObject
  index: number
}
const positions = ['left', 'right']

const AccommodationRoomType = ({ roomType, index }: Props) => {
  const printConfig = useContext(PrintConfigContext)
  const positionMD = positions[Math.floor(index % 2)]
  let positionXXL = positions[Math.floor(index / 2) % positions.length]
  if (printConfig.isPrint) {
    positionXXL = positionMD
  }

  let imageComponent = (
    <div className="box-ratio-16-9">
      <div className={`box-ratio-inner ${styles.imgWrap}`} />
    </div>
  )

  if (roomType.gallery.images.length > 1 && !printConfig.isPrint) {
    imageComponent = <TextWithImageSlider gallery={roomType.gallery} />
  } else if (roomType.gallery.images[0]) {
    // simple image
    const image = roomType.gallery.images[0]
    imageComponent = (
      <div className="box-ratio-16-9">
        <div className={`box-ratio-inner ${styles.imgWrap}`}>
          <CloudinaryImage image={image} width={704} aspectRatio={1.777} responsive={false} className={'card-img d-lg-none'} />
          <CloudinaryImage image={image} width={704} aspectRatio={1.1} responsive={false} className={'card-img d-none d-xl-block'} />
          <CloudinaryImage image={image} width={704} aspectRatio={0.8} responsive={false} className={'card-img d-none d-lg-block d-xl-none'} />
        </div>
      </div>
    )
  }

  return (
    <Card className={`${styles.root} h-100`}>
      <Row className={'h-100'}>
        <Col
          md={{ span: roomType.gallery.images.length > 0 ? 6 : 12, order: positionMD === 'left' ? 'first' : 'last' }}
          xxl={{ span: roomType.gallery.images.length > 0 ? 6 : 12, order: positionXXL === 'left' ? 'first' : 'last' }}
        >
          <Card.Body className={styles.cardBody}>
            <Card.Title className={styles.cardTitle}>{roomType.name}</Card.Title>
            <Card.Text dangerouslySetInnerHTML={{ __html: roomType.description }} />
          </Card.Body>
        </Col>
        {roomType.gallery.images.length > 0 && (
          <Col
            md={6}
            className={`${positionMD === 'left' ? 'ps-md-0' : 'pe-md-0'} ${positionXXL === 'left' ? 'ps-xxl-0 pe-xxl-2' : 'pe-xxl-0 ps-xxl-2'}`}
          >
            {imageComponent}
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default AccommodationRoomType
