import accommodationAvailableAddonObject from '@/entities/accommodationAvailableAddon/interface'
import styles from '@/components/models/accommodation/AccommodationDetailed/AccommodationAvailableAddons/AccommodationAvailableAddons.module.scss'
import { Col, Row } from 'react-bootstrap'
import TextWithIconBlock from '@/components/models/cms/blocks/TextWithIconBlock'
import { useTranslation } from 'next-i18next'

interface Props {
  addons: accommodationAvailableAddonObject[]
}
const AccommodationAvailableAddons = ({ addons }: Props) => {
  const { t } = useTranslation('common')

  return (
    <>
      {addons.length > 0 && (
        <>
          <Row className={'mb-3'}>
            <Col>
              <div className={styles.title}>{t('booking.additionalBookingOptions')}</div>
            </Col>
          </Row>
          <Row>
            {addons.map((addon: accommodationAvailableAddonObject, index: number) => {
              return (
                <Col className={'mb-3'} md={6} key={index}>
                  <TextWithIconBlock title={addon.name} text={addon.description} image={addon.icon} />
                </Col>
              )
            })}
          </Row>
        </>
      )}
    </>
  )
}

export default AccommodationAvailableAddons
