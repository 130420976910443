import styles from './IntroBlock.module.scss'
import travelObject from '@/entities/travel/interface'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IntroBlockLeadText from '@/components/models/travel/IntroBlock/IntroBlockLeadText'
import IntroBlockHighlights from '@/components/models/travel/IntroBlock/IntroBlockHighlights'

interface Props {
  travel: travelObject
}

const IntroBlock = ({ travel }: Props) => {
  return (
    <>
      <div className={styles.root}>
        <Container>
          <Row>
            <Col xl={5}>
              <IntroBlockHighlights highlights={travel.highlights} />
            </Col>
            <Col>
              <IntroBlockLeadText travel={travel} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default IntroBlock
