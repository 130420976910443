import styles from '@/components/models/travel/PricingBlock/PricingBlockServices/PricingBlockServices.module.scss'
import { useTranslation } from 'next-i18next'
import Col from 'react-bootstrap/Col'

interface Props {
  includedServices: string
  excludedServices: string
}

const PricingBlockServices = ({ includedServices, excludedServices }: Props) => {
  const { t } = useTranslation('common')

  return (
    <Col>
      <div className={styles.root}>
        <div className={styles.included}>
          <div className={styles.title}>{t('travel.pricing.includedServices')}</div>
          <div dangerouslySetInnerHTML={{ __html: includedServices }} />
        </div>
        <div className={styles.title}>{t('travel.pricing.excludedServices')}</div>
        <div dangerouslySetInnerHTML={{ __html: excludedServices }} />
      </div>
    </Col>
  )
}

export default PricingBlockServices
