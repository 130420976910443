import styles from './ItineraryBlock.module.scss'
import travelExtensionObject from '@/entities/travelExtension/interface'
import travelObject from '@/entities/travel/interface'
import ItineraryAccordion from '@/components/models/itinerary/ItineraryAccordion'
import { TitleH2 } from '@/components/cms/Title'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'
import TravelExtensionTeaser from '@/components/models/travelExtension/TravelExtensionTeaser'
import { buildExtendedItineraryItems } from '@/components/models/itinerary/functions'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { isFutureDate, stringDateToUTCDate } from '@/lib/helpers/helpers'
// import ItineraryMap from '@/components/models/itinerary/ItineraryMap'
const ItineraryMap = dynamic(() => import('@/components/models/itinerary/ItineraryMap'), {
  ssr: false,
})

interface Props {
  travel: travelObject
  collapsed?: boolean
}
const ItineraryBlock = ({ travel, collapsed }: Props) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { locale } = router

  let hasExtension = false
  let hasProgram = false
  let content = <></>
  const programSubjectToChange = <div className={styles.programDisclaimer}>{t('travel.programSubjectToChange')}</div>
  const extensions = (
    <>
      {travel.travelExtensions.length > 0 && (
        <>
          <h3 className={'mt-5'}>Ferienverlängerung</h3>
          {travel.travelExtensions.map((extension: travelExtensionObject, index: number) => {
            return <TravelExtensionTeaser extension={extension} key={index} />
          })}
        </>
      )}
    </>
  )
  switch (travel.programType) {
    case 'TEXT':
      if (travel.programText) {
        hasProgram = true
        content = (
          <>
            <div className={`mb-3 ${styles.programText}`} dangerouslySetInnerHTML={{ __html: travel.programText }} />
            {programSubjectToChange}
            {extensions}
          </>
        )
      } else if (travel.travelExtensions.length > 0) {
        hasExtension = true
        content = extensions
      }
      break

    case 'ITINERARY':
      // always start with the next upcoming travel date
      const getItineraryStartDate = () => {
        let startDate = new Date()
        if (travel.pricing?.travels[0]) {
          const pricingTravel = travel.pricing.travels[0]
          // remove past dates
          let dates = pricingTravel.dates.filter((date) => {
            return isFutureDate(date.startDate)
          })

          // remove extension dates
          if (dates.length) {
            dates = dates.filter((date) => {
              // @ts-ignore
              return !date.includesExtension
            })
          }

          if (dates.length) {
            startDate = stringDateToUTCDate(dates[0].startDate)
          } else {
            // fallback to first date
            startDate = stringDateToUTCDate(travel.pricing.travels[0].dates[0].startDate)
          }
        }
        return startDate
      }
      const extendedItineraryItems = buildExtendedItineraryItems(travel.itinerary, getItineraryStartDate(), locale)
      if (extendedItineraryItems.length) {
        hasProgram = true
        content = (
          <>
            <Col xl={8} className={styles.accordionCol}>
              <div className={styles.accordionWrap}>
                {travel.travelExtensions.length > 0 && <h3>Hauptreise</h3>}
                {travel.itinerary.items.length > 0 && (
                  <>
                    <ItineraryAccordion extendedItineraryItems={extendedItineraryItems} collapsed={collapsed} />
                    {programSubjectToChange}
                  </>
                )}
              </div>
              {extensions}
            </Col>
            <Col className={styles.mapCol}>
              <ItineraryMap extendedItineraryItems={extendedItineraryItems} />
            </Col>
          </>
        )
      } else if (travel.travelExtensions.length > 0) {
        hasExtension = true
        content = extensions
      }
      break
  }

  return (
    <>
      {content && (
        <Container className={styles.root}>
          <span id="itinerary" className="anchor" />
          {hasProgram && (
            <Row>
              <Col>
                <TitleH2 title={t('travel.itinerary.program')} mb={3} />
              </Col>
            </Row>
          )}
          <Row className={'display-linebreak'}>{content}</Row>
        </Container>
      )}
    </>
  )
}

export default ItineraryBlock
