import styles from './IntroBlockLeadText.module.scss'
import React from 'react'
import travelObject from '@/entities/travel/interface'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { useTranslation } from 'next-i18next'

export interface IntroBlockLeadTextProps {
  travel: travelObject
}
const IntroBlockLeadText = ({ travel }: IntroBlockLeadTextProps) => {
  const { title, description, partners } = travel
  const { t } = useTranslation('common')

  return (
    <div className={styles.root}>
      {/*<div className={styles.travelType}>{travel.travelType.name}</div>*/}
      <div className={styles.title}>{title}</div>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: description }} />
      {partners.length > 0 && (
        <div className={styles.partner}>
          <>
            <div className={styles.label}>{t('travel.inCooperationWith')}</div>
            {partners.map((partner, index) => {
              return (
                <React.Fragment key={index}>
                  {partner.logo.publicId ? (
                    <div className={styles.item}>{<CloudinaryImage image={partner.logo} height={50} responsive={false} />}</div>
                  ) : (
                    <div className={styles.item}>{partner.name}</div>
                  )}
                </React.Fragment>
              )
            })}
          </>
        </div>
      )}
    </div>
  )
}

export default IntroBlockLeadText
