import styles from './GalleryBlock.module.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import imageGalleryObject from '@/entities/imageGallery/interface'
import GallerySlider from '@/components/models/media/GallerySlider'

interface Props {
  gallery: imageGalleryObject
}

const GalleryBlock = ({ gallery }: Props) => {
  if (gallery.images.length) {
    return (
      <Container fluid className={styles.root}>
        <span id="gallery" className="anchor" />
        <Row>
          <Col className="p-0 mb-5">
            <GallerySlider gallery={gallery} />
          </Col>
        </Row>
      </Container>
    )
  }

  return <></>
}

export default GalleryBlock
