import { Badge } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'

interface Props {
  contingent: number
}
const FullyBookedOverlay = ({ contingent }: Props) => {
  const { t } = useTranslation('common')

  return (
    <>
      {contingent === 0 && (
        <div className={'contingentErrorOverlay'}>
          <Badge pill bg="danger">
            {t('booking.fullyBooked')}
          </Badge>
        </div>
      )}
      <style jsx>{`
        .contingentErrorOverlay {
          pointer-events: none;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          background: rgba(255, 255, 255, 0.8);
          width: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      `}</style>
    </>
  )
}

export default FullyBookedOverlay
