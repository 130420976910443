import DefaultIntroBlockLeadText, { IntroBlockLeadTextProps } from 'components/models/travel/IntroBlock/IntroBlockLeadText/IntroBlockLeadText'

const IntroBlockLeadText = ({ travel }: IntroBlockLeadTextProps) => {
  return (
    <>
      <div className={'root'}>
        <DefaultIntroBlockLeadText travel={travel} />
      </div>
      <style jsx>{`
        .root {
          padding-top: 2rem;
        }
      `}</style>
    </>
  )
}

export default IntroBlockLeadText
