import travelGuideObject from '@/entities/travelGuide/interface'
import styles from './TravelGuideTeaserExtended.module.scss'
import { Card, Col, Row } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { useContext } from 'react'
import { PrintConfigContext } from '@/app/providers/PrintConfigProvider'

interface Props {
  travelGuide: travelGuideObject
}

const TravelGuideTeaserExtended = ({ travelGuide }: Props) => {
  const printConfig = useContext(PrintConfigContext)
  return (
    <Card className={styles.root}>
      <Row>
        <Col xl={printConfig.isPrint ? 4 : 6}>
          <div className="box-ratio-16-9">
            <div className={`box-ratio-inner ${styles.imgWrap}`}>
              <CloudinaryImage
                image={travelGuide.image}
                altDefault={`${travelGuide.firstName} ${travelGuide.lastName}`}
                aspectRatio={1.777}
                responsive={false}
                width={635}
                className={'card-img'}
              />
            </div>
          </div>
        </Col>
        <Col xl={printConfig.isPrint ? 8 : 6}>
          <Card.Body className={styles.cardBody}>
            <Card.Title className={styles.cardTitle}>
              {travelGuide.firstName} {travelGuide.lastName}
            </Card.Title>
            <Card.Text as={'div'}>
              <div className={styles.description} dangerouslySetInnerHTML={{ __html: travelGuide.description }} />
              {/*<Row>*/}
              {/*  {travelGuide.questions.map((question: TravelGuideQuestion, index: number) => {*/}
              {/*    return (*/}
              {/*      <Col xs={6} className={`${styles.qaItem} ${!question.answer && 'd-none'}`} key={index}>*/}
              {/*        {question.answer && (*/}
              {/*          <Row>*/}
              {/*            <Col xs={'auto'} className={styles.iconWrap}>*/}
              {/*              <Image src={question.icon.url} width={question.icon.width} height={question.icon.height} alt="icon" />*/}
              {/*            </Col>*/}
              {/*            <Col className={'d-flex align-items-center'}>*/}
              {/*              <div>*/}
              {/*                <div className={styles.question}>{question.question}</div>*/}
              {/*                <div>{question.answer}</div>*/}
              {/*              </div>*/}
              {/*            </Col>*/}
              {/*          </Row>*/}
              {/*        )}*/}
              {/*      </Col>*/}
              {/*    )*/}
              {/*  })}*/}
              {/*</Row>*/}
            </Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  )
}

export default TravelGuideTeaserExtended
