import styles from './PricingBlock.module.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { TitleH2 } from '@/components/cms/Title'
import { useTranslation } from 'next-i18next'
import travelObject from '@/entities/travel/interface'
import PricingBlockInfo from '@/components/models/travel/PricingBlock/PricingBlockInfo'
import PricingBlockServices from '@/components/models/travel/PricingBlock/PricingBlockServices'

interface Props {
  travel: travelObject
}

const PricingBlock = ({ travel }: Props) => {
  const { includedServices, excludedServices, pricingTable, priceCalculationInfo } = travel
  const { t } = useTranslation('common')

  return (
    <Container className={styles.root}>
      <span id="pricing" className="anchor" />
      <Row>
        <TitleH2 title={t('travel.pricesAndServices')} className={styles.title} />
        <PricingBlockInfo pricingTable={pricingTable} priceCalculationInfo={priceCalculationInfo} />
        <PricingBlockServices includedServices={includedServices} excludedServices={excludedServices} />
      </Row>
    </Container>
  )
}

export default PricingBlock
