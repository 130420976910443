import styles from '@/components/models/travelGuide/TravelGuideBlock/TravelGuideBlock.module.scss'
import { TitleH2 } from '@/components/cms/Title'
import { Col, Container, Row } from 'react-bootstrap'
import TravelGuideTeaser from '@/components/models/travelGuide/TravelGuideTeaser'
import TravelGuideTeaserExtended from '@/components/models/travelGuide/TravelGuideTeaserExtended'
import { useTranslation } from 'next-i18next'
import travelGuideObject from '@/entities/travelGuide/interface'
import { useContext } from 'react'
import { PrintConfigContext } from '@/app/providers/PrintConfigProvider'

interface Props {
  travelGuides: Array<travelGuideObject>
}

const buildContent = (tourGuides: Array<travelGuideObject>, isPrint: boolean) => {
  if (isPrint) {
    return (
      <>
        {tourGuides.map((tourGuide: travelGuideObject, index: number) => {
          return (
            <Row key={index} className={'mb-3'}>
              <Col>
                <TravelGuideTeaserExtended travelGuide={tourGuide} />
              </Col>
            </Row>
          )
        })}
      </>
    )
  }

  switch (tourGuides.length) {
    case 1:
      return (
        <Row>
          {tourGuides.map((tourGuide: travelGuideObject, index: number) => {
            return (
              <Col key={index}>
                <TravelGuideTeaserExtended travelGuide={tourGuide} />
              </Col>
            )
          })}
        </Row>
      )

    case 2:
      return (
        <Row>
          {tourGuides.map((tourGuide: travelGuideObject, index: number) => {
            return (
              <Col md={6} className={'mb-3'} key={index}>
                <TravelGuideTeaser travelGuide={tourGuide} />
              </Col>
            )
          })}
        </Row>
      )

    default:
      return (
        <Row>
          {tourGuides.map((tourGuide: travelGuideObject, index: number) => {
            return (
              <Col md={6} lg={4} className={'mb-3'} key={index}>
                <TravelGuideTeaser travelGuide={tourGuide} />
              </Col>
            )
          })}
        </Row>
      )
  }
}

const TravelGuideBlock = ({ travelGuides }: Props) => {
  const { t } = useTranslation('common')
  const printConfig = useContext(PrintConfigContext)

  if (travelGuides.length) {
    return (
      <Container className={styles.root}>
        <span id="guides" className="anchor" />
        <Row className={styles.titleRow}>
          <Col xs={12}>
            <TitleH2 title={t('travel.travelGuide')} overlap={true} className={styles.title} />
          </Col>
          <Col xs={12}>{buildContent(travelGuides, printConfig.isPrint)}</Col>
        </Row>
      </Container>
    )
  }

  return <></>
}

export default TravelGuideBlock
