import styles from './TravelExtensionTeaser.module.scss'
import travelExtensionObject from '@/entities/travelExtension/interface'
import travelHighlightObject from '@/entities/travelHighlight/interface'
import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import FullyBookedOverlay from '@/components/models/booking/BookingWizard/components/FullyBookedOverlay'
import ContingentBadge from '@/components/models/booking/BookingWizard/steps/BookingStepAccommodation/ContingentBadge'

interface Props {
  extension: travelExtensionObject
  priceText?: string
  contingent?: number
  buttonText?: string
  buttonOnClick?: () => void
  buttonVariant?: 'outline-dark'
}

interface ModalProps {
  extension: travelExtensionObject
}

const ModalWidget = ({ extension }: ModalProps) => {
  const { t } = useTranslation('common')
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Button variant={'signal'} onClick={handleShow} className={styles.detailsButton}>
        {t('button.showMore')}
      </Button>
      <Modal show={show} onHide={handleClose} size={'lg'} className={styles.modal} centered>
        <Modal.Header closeButton className={styles.header}>
          <Modal.Title className={styles.title}>{extension.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body} dangerouslySetInnerHTML={{ __html: extension.description }} />
        <Modal.Footer className={styles.footer}>
          <Button variant="light" onClick={handleClose} className={styles.button}>
            {t('button.close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const TravelExtensionTeaser = ({ extension, priceText, contingent = 9999, buttonText, buttonOnClick, buttonVariant }: Props) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Card className={styles.root}>
        <Row>
          <Col md={4} className={'pe-0'}>
            <div className="box-ratio-16-9">
              <div className={`box-ratio-inner ${styles.imgWrap}`}>
                <CloudinaryImage image={extension.image} aspectRatio={1.2} width={320} responsive={false} className={'card-img'} />
              </div>
            </div>
          </Col>
          <Col md={8} className={'ps-0'}>
            <div className={styles.content}>
              <ContingentBadge contingent={contingent} className={'position-absolute top-0 end-0 mt-1'} />
              <div className={styles.title}>{extension.name}</div>
              <div className={styles.date}>{t('days', { count: extension.duration })}</div>
              <div>
                <ul className={styles.list}>
                  {extension.highlights.map((highlight: travelHighlightObject, index: number) => {
                    return <li key={index}>{highlight.text}</li>
                  })}
                </ul>
              </div>
              {buttonText && buttonOnClick ? (
                <div className={styles.bookingInfo}>
                  {contingent > 0 && (
                    <>
                      <Button variant={buttonVariant ? buttonVariant : 'signal'} onClick={buttonOnClick} className={styles.customButton}>
                        {buttonText}
                      </Button>
                      {priceText !== undefined && <div className={styles.price}>{priceText}</div>}
                    </>
                  )}
                </div>
              ) : (
                <ModalWidget extension={extension} />
              )}
            </div>
          </Col>
        </Row>
        <FullyBookedOverlay contingent={contingent} />
      </Card>
    </>
  )
}

export default TravelExtensionTeaser
