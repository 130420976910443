import styles from './HeaderBlock.module.scss'
import { Col, Container, Row } from 'react-bootstrap'
import travelObject from '@/entities/travel/interface'
import { useTranslation } from 'next-i18next'
import { buildTravelDatesLabel } from '@/lib/helpers/travel'
import ResponsiveHeaderImage from '@/components/elements/media/ResponsiveHeaderImage'
import HeaderBlockInfoLabel from '@/components/models/travel/HeaderBlock/HeaderBlockInfoLabel'
import { buildSecondaryLabel } from '@/components/models/travel/HeaderBlock/lib'

interface HeaderBlockProps {
  travel: travelObject
}

const HeaderBlock = ({ travel }: HeaderBlockProps) => {
  const { name, mainImage } = travel
  const { t } = useTranslation('common')

  return (
    <>
      <Container fluid className={styles.root}>
        <Row className="h-100">
          <Col xs={4} className={`gx-0 d-none d-lg-block ${styles.tile}`} />
          <Col xs={12} lg={8} className={`gx-0`}>
            <ResponsiveHeaderImage image={mainImage} withSidebar={true} />
          </Col>
          <div className={styles.content}>
            <div className={styles.title} dangerouslySetInnerHTML={{ __html: name }} />
            <Col xs={12} className={styles.text}>
              {buildSecondaryLabel(travel, t)}
            </Col>
            <div className={styles.ribbonWrap}>
              <HeaderBlockInfoLabel label={buildTravelDatesLabel(travel.pricing.travels, t)} />
            </div>
          </div>
        </Row>
      </Container>
      <style global jsx>
        {`
          main {
            margin-top: 0;
          }
        `}
      </style>
    </>
  )
}

export default HeaderBlock
