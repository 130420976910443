import styles from '@/components/models/accommodation/AccommodationBlock/AccommodationBlock.module.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { TitleH2 } from '@/components/cms/Title'
import { useTranslation } from 'next-i18next'
import AccommodationDetailed from '@/components/models/accommodation/AccommodationDetailed'
import AccommodationTeaser from '@/components/models/accommodation/AccommodationTeaser'
import { useContext } from 'react'
import { AppConfigContext } from '@/app/providers/AppConfigProvider'
import travelAccommodationAssignmentObject from '@/entities/travelAccommodationAssignment/interface'

interface Props {
  accommodationAssignments: travelAccommodationAssignmentObject[]
}

function getVisibleRoomTypes(accommodationAssignment: travelAccommodationAssignmentObject) {
  if (!accommodationAssignment.useCustomRoomTypeSelection) {
    return accommodationAssignment.accommodation.roomTypes
  }
  // @ts-ignore
  const roomTypeIds = accommodationAssignment.roomTypeIds
  return accommodationAssignment.accommodation.roomTypes.filter((roomType) => !!roomTypeIds.includes(roomType.id))
}

function getContent(accommodationAssignments: travelAccommodationAssignmentObject[], denseViewThreshold: number) {
  if (accommodationAssignments.length <= denseViewThreshold) {
    return (
      <>
        {accommodationAssignments.map((accommodationAssignment: travelAccommodationAssignmentObject, index: number) => {
          return (
            <div key={index} className={index > 0 ? 'mt-5' : ''}>
              <AccommodationDetailed accommodation={accommodationAssignment.accommodation} roomTypes={getVisibleRoomTypes(accommodationAssignment)} />
            </div>
          )
        })}
      </>
    )
  } else {
    return (
      <Container>
        <Row>
          {accommodationAssignments.map((accommodationAssignment: travelAccommodationAssignmentObject, index: number) => {
            return (
              <Col md={6} key={index} className={'mb-3'}>
                <AccommodationTeaser accommodation={accommodationAssignment.accommodation} />
              </Col>
            )
          })}
        </Row>
      </Container>
    )
  }
}

const AccommodationBlock = ({ accommodationAssignments }: Props) => {
  const { t } = useTranslation('common')
  const appConfig = useContext(AppConfigContext)

  if (accommodationAssignments.length) {
    return (
      <div className={styles.root}>
        <Container className={'mb-2'}>
          <span id="accommodation" className="anchor" />
          <Row>
            <Col>
              <TitleH2 title={t(accommodationAssignments.length == 1 ? 'travel.accommodation' : 'travel.accommodations')} withDecoration={true} />
            </Col>
          </Row>
        </Container>
        {getContent(accommodationAssignments, appConfig.travel.accommodations.denseViewThreshold)}
      </div>
    )
  }

  return <></>
}

export default AccommodationBlock
