import styles from './ContingentBadge.module.scss'
import { Badge } from 'react-bootstrap'
import { useTranslation } from 'next-i18next'

interface Props {
  contingent: number
  className?: string
}
const ContingentBadge = ({ contingent, className = '' }: Props) => {
  const { t } = useTranslation('common')

  return (
    <>
      {contingent <= 5 && contingent > 0 && (
        <div className={`d-block ${className}`}>
          <Badge bg="warning" text="dark" pill className={styles.badge}>
            <small>{t('booking.contingentWarning', { count: contingent })}</small>
          </Badge>
        </div>
      )}
    </>
  )
}

export default ContingentBadge
